import type {QueryFunctionContext, UseQueryResult} from 'react-query'
import {useQuery} from 'react-query'
import type {DiseaseByPlaceIdResponse} from '@/_new-code/products/disease-map/fetch-disease'
import type {GetCaseArgs} from '@/_new-code/services/disease-api/models'
import http from '@/utils/axios'
import type {GetReturnType} from '@/_new-code/services/disease-api/api'

async function fetchDiseaseByPlaceId({
	queryKey: [, args],
}: QueryFunctionContext<[string, GetCaseArgs]>): Promise<GetReturnType> {
	const ENDPOINT = '/api/disease/group-by-administrative-area'
	const params = new URLSearchParams(
		Object.entries(args)
			.map<[string, string] | null>(
				([key, value]: [string, GetCaseArgs[keyof GetCaseArgs]]) => {
					if (
						typeof value === 'undefined' ||
						(typeof value === 'string' && value === '')
					)
						return null
					if (typeof value === 'string') return [key, value]
					if (typeof value === 'number') return [key, String(value)]
					return [key, value.join(',')]
				}
			)
			.filter(Boolean)
	)

	if (args.startDate)
		params.set('startDate', args.startDate.split('T')[0] ?? '')

	if (args.endDate) params.set('endDate', args.endDate.split('T')[0] ?? '')

	const endpoint = `${ENDPOINT}?${params.toString()}`
	const {data} = await http<DiseaseByPlaceIdResponse>(endpoint)
	return data
}

export function useDiseaseData(
	args: GetCaseArgs
): UseQueryResult<GetReturnType, {success: false; error: unknown}> {
	return useQuery(['DiseaseCasesByPlaceId', args], fetchDiseaseByPlaceId, {
		refetchOnWindowFocus: false,
		cacheTime: 15 * (60 * 1000),
	})
}
